.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.field-with-select {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 1rem;
}

div.p-field {
  padding-bottom: 1rem;
}

.form-sub-heading {
  display: flex;
  gap: 1rem;
  background-color: #E6E6E6;
  margin-bottom: 0.5rem;
  align-items: center;
  height: 3rem;
  padding-right: 1rem;
}

.form-sub-heading > span {
  width: 16px;
  background-color: #ef4444;
  height: 100%;
}

.delete-confirm-buttons-container {
  display: flex;
  width: 100%;
  justify-content: end;
  gap: 1rem;
}

.readable-cell {
  min-width: 9rem;
}

.full-w-header {
  min-width: 100%;
  display: flex;
  justify-content: space-between;
}

.p-card {
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
}

.btn-off {
  background-color: rgb(136, 136, 136);
  border-color: transparent;
}

.btn-off:hover {
  background-color: rgb(112, 112, 112);
}

a.p-button {
  text-decoration: none;
  max-width: fit-content;
}

.p-panel .p-panel-content {
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
  border-radius: 6px;
  border-top: 1px solid #e5e7eb;;
}
